import React from 'react';
import PropTypes from 'prop-types';
import featureSwitch from '../services/featureSwitch';
import callApi, { getJwtToken, removeJwtToken } from '../services/api';

function setShopName(name) {
  if (typeof localStorage !== 'undefined') localStorage.setItem('shopName', name);
}

function getShopName() {
  return typeof localStorage !== 'undefined' && localStorage.getItem('shopName');
}

const defaultState = {
  isAuth: !featureSwitch('auth_shops'),
  shopName: getShopName(),
};

const AuthContext = React.createContext(defaultState);

class AuthProvider extends React.Component {
  constructor() {
    super();
    this.state = defaultState;
    this.setAuth = this.setAuth.bind(this);
  }

  componentDidMount() {
    if (!this.state.isAuth && getJwtToken()) {
      callApi('shops/renewToken', 'POST')
        .then(() => this.setAuth(true))
        .catch(() => this.setAuth(false));
    }
  }

  setAuth(isAuth) {
    if (!isAuth) removeJwtToken();
    this.setState({ isAuth });
  }

  render() {
    const { children } = this.props;
    const { isAuth } = this.state;
    const { setAuth } = this;
    const shopName = getShopName();
    const authContext = { isAuth, setAuth, setShopName, shopName };
    return (
      <AuthContext.Provider value={authContext} >
        {children}
      </AuthContext.Provider>
    );
  }
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;

export { AuthProvider };
