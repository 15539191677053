import React from 'react';
import PropTypes from 'prop-types';

const defaultState = {
  clothes: [],
  slots: {},
  addOrEditCloth() {},
  setSlots() {},
};

const initialState = {
  orderId: null,
  editingClothIndex: null,
  clothes: [],
  slots: {},
  promoCode: {
    code: '',
  },
  discounts: [],
  deliveryFee: 5,
  rdv3ClicksOrder: {
    knowHow: [],
    fabrics: [],
  },
  isFastVariant: true,
};

const OrderContext = React.createContext(defaultState);

class OrderProvider extends React.Component {
  constructor() {
    super();
    this.state = { ...initialState };
    this.addOrEditCloth = this.addOrEditCloth.bind(this);
    this.setSlots = this.setSlots.bind(this);
    this.setPromoCode = this.setPromoCode.bind(this);
    this.unsetPromoCode = this.unsetPromoCode.bind(this);
    this.setOrderId = this.setOrderId.bind(this);
    this.setEditingClothIndex = this.setEditingClothIndex.bind(this);
    this.deleteCloth = this.deleteCloth.bind(this);
    this.updateDeliveryFee = this.updateDeliveryFee.bind(this);
    this.setRDV3ClicksOrder = this.setRDV3ClicksOrder.bind(this);
    this.reinit = this.reinit.bind(this);
  }

  setSlots(slots) {
    this.setState({ slots });
  }

  setPromoCode(code) {
    let promoCode = this.state.promoCode;
    if (typeof code === 'string') {
      promoCode.code = code;
    } else {
      promoCode = code;
    }
    this.setState({ promoCode });
  }

  setOrderId(orderId) {
    this.setState({ orderId });
  }

  setEditingClothIndex(editingClothIndex, callback = () => {}) {
    this.setState({ editingClothIndex, isFastVariant: false }, callback);
  }

  setRDV3ClicksOrder(knowHow, fabrics) {
    this.setState({ rdv3ClicksOrder: {
      knowHow,
      fabrics,
    } });
  }

  unsetPromoCode() {
    this.setState({ promoCode: null });
  }

  addOrEditCloth(newCloth) {
    const { clothes, editingClothIndex } = this.state;
    const newClothes = typeof editingClothIndex === 'number'
      ? clothes.map((cloth, index) => index === editingClothIndex ? newCloth : cloth)
      : [...clothes, newCloth];
    this.setState({ clothes: newClothes, editingClothIndex: null });
  }

  deleteCloth(clothIndex) {
    const { clothes } = this.state;
    this.setState({ clothes: clothes.filter((cloth, index) => index !== clothIndex) });
  }

  updateDeliveryFee(price) {
    this.setState({ deliveryFee: price });
  }

  reinit() {
    this.setState({ ...initialState });
  }

  render() {
    const { children } = this.props;
    const {
      orderId, promoCode, slots, deliveryFee,
      clothes, editingClothIndex,
      rdv3ClicksOrder, isFastVariant,
    } = this.state;
    const editingCloth = (typeof editingClothIndex === 'number')
      ? clothes[editingClothIndex]
      : undefined;
    const orderContext = {
      orderId,
      clothes,
      slots,
      promoCode,
      discounts: (promoCode && promoCode.value > 0) ? [promoCode] : [],
      deliveryFee,
      editingCloth,
      editingClothIndex,
      rdv3ClicksOrder,
      isFastVariant,
      addOrEditCloth: this.addOrEditCloth,
      setSlots: this.setSlots,
      setPromoCode: this.setPromoCode,
      unsetPromoCode: this.unsetPromoCode,
      setOrderId: this.setOrderId,
      setEditingClothIndex: this.setEditingClothIndex,
      deleteCloth: this.deleteCloth,
      updateDeliveryFee: this.updateDeliveryFee,
      setRDV3ClicksOrder: this.setRDV3ClicksOrder,
      reinit: this.reinit,
    };
    return (
      <OrderContext.Provider value={orderContext} >
        {children}
      </OrderContext.Provider>
    );
  }
}

OrderProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OrderContext;

export { OrderProvider };
